<template>
  <div class="page-content w-100">
    <!-- start heading -->
    <div class="row">
      <div class="col-sm-12">
        <p class="text-center page-heading">Publish my wisdom</p>
      </div>
    </div>
    <!--  -->
    <div class="p-4" style="background-color: #ffff;border-radius: 20px;">
      <div class="row">
        <div class="col-12 col-md-12 mx-auto">
          <div class="mt-3 row">
            <div class="col-sm-12">
              <label for="inputPassword" class="col-form-label">To</label>
            </div>
            <div class="col-11">
              <select @change="onChange($event)" placeholder="All my followers / groups"
                style="width: 110%;height: 35px;">
                <option selected>Select</option>
                <option value="1">Everyone</option>
                <option value="2">Patient groups</option>
                <option value="3">Practice communities</option>
              </select>
            </div>
            <div class="col-1" v-if="isCheck && arrayOption.length > 1">
              <img v-if=this.isOpen src="~@/assets/images/hide.png" @click="onClick($event)"
                style="width: 40px;margin-left: 55px;" />
              <img v-if=!this.isOpen src="~@/assets/images/show.png" @click="onClick($event)"
                style="width: 40px;margin-left: 55px;" />
            </div>

            <div class="col-1" v-if="isChecked && groupOption.length > 1">
              <img v-if=this.isOpened src="~@/assets/images/hide.png" @click="onClick($event)"
                style="width: 40px;margin-left: 55px;" />
              <img v-if=!this.isOpened src="~@/assets/images/show.png" @click="onClick($event)"
                style="width: 40px;margin-left: 55px;" />
            </div>

          </div>
          <div class="row" v-if="isOpen && isCommmunity">
            <div class="col-12 col-sm-4 col-lg-4" v-for="index in arrayOption" :key="index">
              <label class="checkbox-inline">
                <input type="checkbox" class="me-2" name="practice[]" id="cardiology" v-model="index.selected"
                  value="Cardiology" />
                {{ index.practiceArea }}</label>
            </div>
          </div>

          <div class="row" v-if="isOpened && isPatient">
            <div class="col-12 col-sm-4 col-lg-4" v-for="index in groupOption" :key="index">
              <label class="checkbox-inline">
                <input type="checkbox" class="me-2" name="groupname" id="groupname" v-model="index.selected"
                  value="groupname" />
                {{ index.groupname }}</label>
            </div>
          </div>

          <div class="mt-2">
            <textarea
              placeholder="Share your wisdom in 1,500 characters. Ensure that it helps members of the RxIx community.."
              rows="8" class="form-control" id="post_wisdom"></textarea>
          </div>
          <div class="my-4 text-center">
            <button type="button" class="btn btn-color text-white">Cancel</button>
            <span class="ms-3"><button type="button" class="btn btn-blue-color text-white"
                style="width: auto;">Publish</button></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  components: {},
  data() {
    return {
      area: "",
      selected: [],
      options: [],
      Option: [],
      arrayOption: [],
      groupOption: [],
      isOpen: false,
      isCheck: false,
      isOpened: false,
      isChecked: false,
      valueCheck: '',
      isPatient: false,
      isCommmunity: false
    };
  },
  methods: {
    getPracticeArea() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
        .get(`${BASE_API_URL}/hcp-types?hcpTypes=doctor`)
        .then((response) => {
          if (response?.data) {
            this.options = response.data;
            this.Option = response.data;
          }
        })
        .catch(function (error) {
          console.log(error.response);
        });
      axios
        .get(`${BASE_API_URL}/hcps/${localStorage.getItem('id')}/one`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.arrayOption = []
          if (response?.data) {
            if (response.data.practiceId) {
              let value = JSON.parse(response.data.practiceId);
              value.forEach(item => {
                this.Option.forEach(element => {
                  if (element.id == item) {
                    element.selected = true
                    element.checked = true
                    this.arrayOption.push(element);
                  }
                });
              });
              let payload = { practice_community: this.arrayOption }
              axios
                .put(`${BASE_API_URL}/hcps/${localStorage.getItem('id')}/update`, payload, {
                  headers: {
                    "Content-Type": "application/json",
                  },
                })
                .then((response) => {
                  console.log(response);
                })
            }
          }
        })
        .catch(function (error) {
          console.log(error?.response);
        })

    },
    onChange(event) {
      this.valueCheck = event.target.value
      if (this.valueCheck === '3') {
        this.isCheck = true;
        this.isCommmunity = true;
        this.isPatient = false;
      }
      if (this.valueCheck === '2') {
        this.isCheck = true
        this.isCommmunity = false;
        this.isPatient = true;
      }
    },
    onClick(event) {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      console.log(event);
      this.isOpen = this.isOpen ? false : true;
      this.isOpened = this.isOpened ? false : true;
      axios
        .get(`${BASE_API_URL}/patient-support/add`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.groupOption = []
          if (response?.data) {
            let patientData = response.data
            patientData.forEach((data) => {
              data.selected = true;
              if (data.patient_support_status === true && data.patient_support_status !== null) {
                this.groupOption.push(data)
                // this.groupData.push(data)
                this.groupOpen = true;
              }
              else {
                if (data.patient_support_status == false) {
                  // this.groupremoveData.push(data)
                  this.groupRemove = true;
                }
              }
            })
          }
        })
    }
  },
  created: function () {
    this.getPracticeArea()
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>